var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
const anythingFilled = (...strings) => {
    for (const str of strings) {
        if (str == null)
            continue;
        if (str.trim().length > 0)
            return true;
    }
    return false;
};
let NameType = class NameType {
    constructor(ecb) {
        this.ecb = ecb;
    }
    getAnswerField(field) {
        if (this.answer != null && this.answer.Data != null) {
            return this.answer.Data[field] || null;
        }
        return null;
    }
    setAnswerField(field, value) {
        if (this.answer == null)
            return;
        if (this.answer.Data == null) {
            this.answer.Data = {
                title: null,
                given: null,
                middle: null,
                family: null,
                suffix: null,
            };
        }
        this.answer.Data[field] = value;
        this.ecb.dispatch("answer-changed");
    }
    get title() { return this.getAnswerField("title"); }
    set title(str) { this.setAnswerField("title", str); }
    get given() { return this.getAnswerField("given"); }
    set given(str) { this.setAnswerField("given", str); }
    get middle() { return this.getAnswerField("middle"); }
    set middle(str) { this.setAnswerField("middle", str); }
    get family() { return this.getAnswerField("family"); }
    set family(str) { this.setAnswerField("family", str); }
    get suffix() { return this.getAnswerField("suffix"); }
    set suffix(str) { this.setAnswerField("suffix", str); }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], NameType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], NameType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NameType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NameType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NameType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], NameType.prototype, "ariaLabelledby", void 0);
NameType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], NameType);
export { NameType };

NameType.__template = require('./name.html');
