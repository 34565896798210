var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { fromClass, fromClassArray } from "fw-model";
export class PortalMetaData {
}
export class InquiryPortalContent {
    constructor() {
        this.HeaderHtml = null;
        this.FooterHtml = null;
        this.Css = null;
        this.GlobalCss = null;
        this.ConfirmationHtml = null;
        this.HomeHtml = null;
    }
}
export class InquiryPortalForm {
    constructor() {
        this.FormId = null;
        this.PageSlug = null;
    }
}
export class InquiryPortal {
}
__decorate([
    fromClassArray(InquiryPortalForm),
    __metadata("design:type", Array)
], InquiryPortal.prototype, "Forms", void 0);
__decorate([
    fromClass,
    __metadata("design:type", PortalMetaData)
], InquiryPortal.prototype, "MetaData", void 0);
__decorate([
    fromClass,
    __metadata("design:type", InquiryPortalContent)
], InquiryPortal.prototype, "Content", void 0);
