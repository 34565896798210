var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { needs, inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
import { QuestionScaleMode } from "../../enums";
import { Icon } from "../icon";
let ScaleType = class ScaleType {
    constructor(ecb) {
        this.ecb = ecb;
        this.options = [];
        this.endPointStart = null;
        this.endPointEnd = null;
    }
    attached() {
        const options = this.question.Options.Scale;
        switch (options.Mode) {
            case QuestionScaleMode.Range:
                this.endPointStart = options.Values[0].Label;
                this.endPointEnd = options.Values[1].Label;
                this.options = [];
                for (let i = options.Values[0].Value; i <= options.Values[1].Value; i++) {
                    this.options.push({ label: i.toString(), value: i });
                }
                break;
            case QuestionScaleMode.Labels:
                this.options = [];
                this.options = options.Values.map(v => ({ label: v.Label, value: v.Value }));
                break;
        }
    }
    change() {
        this.answer.Text = this.answer.Number != null ? `${this.answer.Number}` : null;
        this.ecb.dispatch("answer-changed");
    }
    clearAnswer() {
        this.answer.Number = null;
        this.change();
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], ScaleType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], ScaleType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], ScaleType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], ScaleType.prototype, "displayonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ScaleType.prototype, "ariaLabelledby", void 0);
ScaleType = __decorate([
    needs(Icon),
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], ScaleType);
export { ScaleType };

ScaleType.__template = require('./scale.html');
