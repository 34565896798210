var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject } from "fw";
import { DialogService } from "fw-dialog";
import { NotificationDialog, NotificationType } from "views/components/notification-dialog";
let Notification = class Notification {
    constructor(dialogService) {
        this.dialogService = dialogService;
        this.messages = [];
    }
    notify(message) {
        this.pushMessage(message, "success");
    }
    notifyWarning(message) {
        this.pushMessage(message, "warning");
    }
    error(message, title = "Something went wrong.") {
        return __awaiter(this, void 0, void 0, function* () {
            this.pushMessage(message, "error");
        });
    }
    successDialog(message, title = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationArgs = {
                title,
                text: message,
                type: NotificationType.Success
            };
            yield this.dialogService.open(NotificationDialog, notificationArgs, { cssClass: 'c-notification-dialog' });
        });
    }
    errorDialog(message, title = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationArgs = {
                title,
                text: message,
                type: NotificationType.Error
            };
            yield this.dialogService.open(NotificationDialog, notificationArgs, { cssClass: 'c-notification-dialog' });
        });
    }
    warningDialog(message, title = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationArgs = {
                title,
                text: message,
                type: NotificationType.Warning
            };
            yield this.dialogService.open(NotificationDialog, notificationArgs, { cssClass: 'c-notification-dialog' });
        });
    }
    pushMessage(message, cssClass) {
        const element = document.createElement("div");
        element.classList.add("notification", cssClass);
        element.innerText = message;
        document.body.appendChild(element);
        this.moveUpMessages();
        const theMessage = {
            message,
            dom: element,
            order: 0,
        };
        this.messages.push(theMessage);
        setTimeout(() => {
            element.classList.add("open-0");
        }, 10);
        setTimeout(() => {
            element.classList.add(`close-${theMessage.order}`);
            element.classList.remove(`open-${theMessage.order}`);
            setTimeout(() => {
                element.remove();
                const idx = this.messages.indexOf(theMessage);
                if (idx != -1)
                    this.messages.splice(idx, 1);
            }, 500);
        }, 5000);
    }
    moveUpMessages() {
        this.messages.forEach(m => {
            m.dom.classList.remove(`open-${m.order}`);
            m.order += 1;
            m.dom.classList.add(`open-${m.order}`);
        });
    }
};
Notification = __decorate([
    inject,
    __metadata("design:paramtypes", [DialogService])
], Notification);
export { Notification };
