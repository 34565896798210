var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { fromClass, fromClassArray } from "fw-model";
import { Organization } from "./organization";
import { InquiryPortal } from "./inquiry-portal";
import { Form } from "./form";
export class OrganizationContext {
    constructor() {
        this.FormsUseFullName = {};
        this.FeatureFlags = {};
    }
}
__decorate([
    fromClass,
    __metadata("design:type", Organization)
], OrganizationContext.prototype, "Organization", void 0);
__decorate([
    fromClass,
    __metadata("design:type", InquiryPortal)
], OrganizationContext.prototype, "InquiryPortal", void 0);
__decorate([
    fromClassArray(Form),
    __metadata("design:type", Array)
], OrganizationContext.prototype, "Forms", void 0);
