var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
import { calcColumnWidth } from "../../helpers";
let TableType = class TableType {
    constructor(ecb) {
        this.ecb = ecb;
        this.didInit = false;
        this.columns = [];
        this.answers = [];
    }
    attached() {
        const options = this.question.Options.Table;
        this.answer.Rows = this.answer.Rows || [];
        this.mapAnswers();
        const frontPercent = options.RowNames.length > 0 ? 25 : 0;
        options.Columns.forEach(c => {
            this.columns.push({
                label: c.Label,
                percentWidth: calcColumnWidth(options, c.Width, frontPercent),
            });
        });
        this.didInit = true;
    }
    mapAnswers() {
        if (this.answer != null && this.answer.Rows != null) {
            this.answers = this.answer.Rows.map(a => a.map(a2 => ({ answer: a2 })));
        }
        else {
            this.answers = [];
        }
        const options = this.question.Options.Table;
        if (options.RowNames.length > 0) {
            for (let i = this.answers.length; i < options.RowNames.length; i++) {
                this.addRow(false);
            }
        }
        else {
            for (let i = this.answers.length; i < Math.max(options.MinRowCount, 1); i++) {
                this.addRow(false);
            }
        }
    }
    answerChanged() {
        this.didInit = false;
        this.mapAnswers();
        this.didInit = true;
    }
    get canDelete() {
        if (this.question == null)
            return false;
        const options = this.question.Options.Table;
        return this.answers.length > Math.max(options.MinRowCount, 1);
    }
    get canAdd() {
        return (!this.readonly &&
            this.didInit &&
            this.answers != null &&
            this.answers.length < this.question.Options.Table.MaxRowCount &&
            this.question.Options.Table.RowNames.length == 0);
    }
    change() {
        this.answer.Rows = this.answers.map(a => a.map(a2 => a2.answer));
        this.ecb.dispatch("answer-changed");
    }
    addRow(change = true) {
        const options = this.question.Options.Table;
        this.answers.push(options.Columns.map(c => ({ answer: "" })));
        if (change)
            this.change();
    }
    removeRow(idx) {
        this.answers.splice(idx, 1);
        this.change();
    }
    ordinalize(num) {
        const suffix = ["th", "st", "nd", "rd"][Math.abs(~[1, 2, 3].indexOf(+(+num).toFixed().substr(-1)))];
        return num + suffix;
    }
    cellLabel(rowIndex, colIndex) {
        return `${this.ordinalize(rowIndex + 1)} row. ${this.columns[colIndex].label}`;
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], TableType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], TableType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], TableType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], TableType.prototype, "displayonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TableType.prototype, "ariaLabelledBy", void 0);
TableType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], TableType);
export { TableType };

TableType.__template = require('./table.html');
