const setHTML = (o, html) => {
    o.innerHTML = "";
    // Generate a parseable object with the html:
    var dv = document.createElement("div");
    dv.innerHTML = html;
    if (dv.children.length == 0)
        o.innerHTML = html;
    for (var i = 0; i < dv.children.length; i++) {
        var c = dv.children[i];
        // n: new node with the same type as c
        var n = document.createElement(c.nodeName);
        // copy all attributes from c to n
        for (var j = 0; j < c.attributes.length; j++)
            n.setAttribute(c.attributes[j].nodeName, c.attributes[j].nodeValue);
        // If current node is a leaf, just copy the appropriate property (text or innerHTML)
        if (c.children.length == 0) {
            switch (c.nodeName) {
                case "SCRIPT":
                    if (c.text)
                        n.text = c.text;
                    break;
                default:
                    if (c.innerHTML)
                        n.innerHTML = c.innerHTML;
                    break;
            }
        }
        // If current node has sub nodes, call itself recursively:
        else
            setHTML(n, c.innerHTML);
        o.appendChild(n);
    }
};
export const plugin = {
    install: Vue => {
        Vue.directive("html-with-script", {
            // we are only implementing the bind here...  this assumes
            // that the binding pretty much never changes
            // we would need to use the `update:..` option here to handle changes
            // but that causes script tags to get executed more than once in some instances..
            // we could probably track changes too.. but we are going
            // to see how far this gets us :)
            bind: function (el, binding) {
                setHTML(el, binding.value);
            },
        });
    },
};
export default plugin;
