export var CalculatedFieldDataTypeCode;
(function (CalculatedFieldDataTypeCode) {
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Number"] = 0] = "Number";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["String"] = 1] = "String";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Date"] = 2] = "Date";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Boolean"] = 3] = "Boolean";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["File"] = 4] = "File";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Enumeration"] = 5] = "Enumeration";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Encrypted"] = 6] = "Encrypted";
    CalculatedFieldDataTypeCode[CalculatedFieldDataTypeCode["Json"] = 7] = "Json";
})(CalculatedFieldDataTypeCode || (CalculatedFieldDataTypeCode = {}));
export var FormTypeCode;
(function (FormTypeCode) {
    FormTypeCode[FormTypeCode["Applicant"] = 0] = "Applicant";
    FormTypeCode[FormTypeCode["Recommender"] = 1] = "Recommender";
    FormTypeCode[FormTypeCode["Evaluation"] = 2] = "Evaluation";
    FormTypeCode[FormTypeCode["Inquiry"] = 3] = "Inquiry";
})(FormTypeCode || (FormTypeCode = {}));
export var QuestionType;
(function (QuestionType) {
    QuestionType[QuestionType["ShortText"] = 1] = "ShortText";
    QuestionType[QuestionType["LongText"] = 2] = "LongText";
    QuestionType[QuestionType["RadioButton"] = 3] = "RadioButton";
    QuestionType[QuestionType["DropDown"] = 4] = "DropDown";
    QuestionType[QuestionType["CheckBoxList"] = 5] = "CheckBoxList";
    QuestionType[QuestionType["File"] = 6] = "File";
    QuestionType[QuestionType["Date"] = 7] = "Date";
    QuestionType[QuestionType["CEEBCode"] = 8] = "CEEBCode";
    QuestionType[QuestionType["EmailAddress"] = 9] = "EmailAddress";
    QuestionType[QuestionType["PhoneNumber"] = 10] = "PhoneNumber";
    QuestionType[QuestionType["URL"] = 11] = "URL";
    QuestionType[QuestionType["Number"] = 12] = "Number";
    QuestionType[QuestionType["Scale"] = 13] = "Scale";
    QuestionType[QuestionType["Table"] = 14] = "Table";
    QuestionType[QuestionType["Address"] = 15] = "Address";
    QuestionType[QuestionType["Name"] = 16] = "Name";
    QuestionType[QuestionType["ScaleGroup"] = 17] = "ScaleGroup";
    QuestionType[QuestionType["Encrypted"] = 18] = "Encrypted";
})(QuestionType || (QuestionType = {}));
export var QuestionScaleMode;
(function (QuestionScaleMode) {
    QuestionScaleMode[QuestionScaleMode["Range"] = 0] = "Range";
    QuestionScaleMode[QuestionScaleMode["Labels"] = 1] = "Labels";
})(QuestionScaleMode || (QuestionScaleMode = {}));
export var NumberQuestionType;
(function (NumberQuestionType) {
    NumberQuestionType[NumberQuestionType["Integer"] = 0] = "Integer";
    NumberQuestionType[NumberQuestionType["Decimal"] = 1] = "Decimal";
})(NumberQuestionType || (NumberQuestionType = {}));
export var CEEBSourceFileType;
(function (CEEBSourceFileType) {
    CEEBSourceFileType[CEEBSourceFileType["College"] = 0] = "College";
    CEEBSourceFileType[CEEBSourceFileType["HighSchoolAndScholarship"] = 1] = "HighSchoolAndScholarship";
})(CEEBSourceFileType || (CEEBSourceFileType = {}));
