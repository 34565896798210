var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
const WRITE_IN = "writeIn";
let CheckboxType = class CheckboxType {
    constructor(ecb) {
        this.ecb = ecb;
        this.value = [];
        this.writeInValue = "";
    }
    attached() {
        this.updateValue();
    }
    updateValue() {
        const { FormAnswerOptions } = this.answer;
        if (FormAnswerOptions != null) {
            this.value = FormAnswerOptions.map(a => a.AnswerOptionId == null ? WRITE_IN : a.AnswerOptionId);
            const writeInOption = FormAnswerOptions.find(a => a.AnswerOptionId == null);
            if (writeInOption != null) {
                this.writeInValue = writeInOption.OptionText;
            }
        }
    }
    answerChanged() {
        this.updateValue();
    }
    isAnswer(answerOption) {
        for (let answerValue of this.value) {
            if (answerOption == answerValue) {
                return true;
            }
        }
        return false;
    }
    change() {
        const options = this.value.map(v => {
            const qo = this.question.AnswerOptions.find(o => o.Id == v);
            const isWriteIn = v == WRITE_IN;
            let OptionText = qo ? qo.Label : null;
            if (isWriteIn) {
                OptionText = this.writeInValue;
            }
            return { AnswerOptionId: isWriteIn ? null : v, OptionText };
        });
        this.answer.FormAnswerOptions = options;
        this.ecb.dispatch("answer-changed");
    }
    writeInKeyUp() {
        this.enableWriteIn();
        this.change();
    }
    enableWriteIn() {
        const hasWriteIn = this.value.includes(WRITE_IN);
        const hasWriteInValue = this.writeInValue.trim().length > 0;
        if (hasWriteIn && !hasWriteInValue) {
            const writeInIndex = this.value.indexOf(WRITE_IN);
            this.value.splice(writeInIndex, 1);
        }
        else if (!hasWriteIn && hasWriteInValue) {
            this.value.push(WRITE_IN);
        }
    }
    writeInCheckboxClick(event) {
        this.writeInInputRef.focus();
        const hasWriteIn = this.value.includes(WRITE_IN);
        const hasWriteInValue = this.writeInValue.trim().length > 0;
        if (hasWriteIn) {
            const writeInIndex = this.value.indexOf(WRITE_IN);
            this.value.splice(writeInIndex, 1);
        }
        else if (hasWriteInValue) {
            this.value.push(WRITE_IN);
        }
        if (!hasWriteInValue) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.change();
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], CheckboxType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], CheckboxType.prototype, "answerOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], CheckboxType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CheckboxType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CheckboxType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CheckboxType.prototype, "compact", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], CheckboxType.prototype, "showOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", String)
], CheckboxType.prototype, "ariaLabelledby", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], CheckboxType.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], CheckboxType.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], CheckboxType.prototype, "fileService", void 0);
CheckboxType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], CheckboxType);
export { CheckboxType };

CheckboxType.__template = require('./checkbox.html');
