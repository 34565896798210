var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormAnswer } from "./models";
import { validateAll, isAnswered } from "./validation";
const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
export const WORKER_FILE_NAME = "condition-resolver";
export class FormResolverService {
    constructor() {
        this.resolverHash = {};
        this.worker = new Worker(new URL('./worker/condition-resolver.worker', import.meta.url));
        this.worker.onmessage = this.onMessage.bind(this);
    }
    onMessage(e) {
        const { id, sections, error, calculatedValues, rawResult, isRaw } = e.data;
        if (error != null) {
            this.resolverHash[id].reject(new Error(error));
        }
        else {
            if (isRaw) {
                this.resolverHash[id].rawResolver(rawResult);
            }
            else {
                this.resolverHash[id].resolver({ sections, calculatedValues });
            }
        }
        delete this.resolverHash[id];
    }
    resolveForm(form, answers, baseAnswers = [], programPropertiesHash = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = s4() + s4() + "-" + s4() + s4();
            const promise = new Promise((resolver, reject) => {
                this.resolverHash[id] = { resolver, reject, rawResolver: null };
            });
            let answersToSend = [];
            if (Array.isArray(answers)) {
                answersToSend = answers;
            }
            else {
                for (const key in answers) {
                    answersToSend.push(answers[key]);
                }
            }
            this.worker.postMessage({ id, form, answers: answersToSend, baseAnswers: baseAnswers || [], programPropertiesHash });
            return promise;
        });
    }
    pruneAnswers(form, answers) {
        return answers.filter(aa => {
            for (const section of form.Sections) {
                // special handling for table sections
                if (section.IsTableSection && section.TableSectionOptions && aa.SectionKey == section.TableSectionOptions.Key) {
                    for (const sectionAnswer of aa.SectionAnswers) {
                        sectionAnswer.Answers = sectionAnswer.Answers.filter(sa => {
                            const question = section.Questions.find(q => q.Key == sa.QuestionKey);
                            return isAnswered(question, sa);
                        });
                    }
                    aa.SectionAnswers = aa.SectionAnswers.filter(sa => sa.Answers.length > 0);
                    return aa.SectionAnswers.length > 0;
                }
                const question = section.Questions.find(q => q.Key == aa.QuestionKey);
                if (question != null) {
                    return isAnswered(question, aa);
                }
            }
            return false;
        });
    }
    ;
    // this will resolve a form, fill in any blank answers for you, and then validate
    validateResolved(form, answers) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield this.resolveForm(form, answers);
            const keys = [];
            results.sections.forEach(cs => {
                const section = form.Sections[cs.sectionIndex];
                if (!section.IsTableSection) {
                    cs.questions.forEach(q => keys.push(q.key));
                }
            });
            let theAnswers = [];
            if (Array.isArray(answers)) {
                theAnswers = answers.slice();
            }
            else {
                for (const key in answers)
                    theAnswers.push(answers[key]);
            }
            // fill out answers that are not in the list
            keys.forEach(key => {
                if (theAnswers.find(a => a.QuestionKey == key) == null) {
                    const newAnswer = new FormAnswer();
                    newAnswer.QuestionKey = key;
                    theAnswers.push(newAnswer);
                }
            });
            return validateAll(form, theAnswers);
        });
    }
    // need to run some javascript? ok! here you go!
    runJs(code, context) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = s4() + s4() + "-" + s4() + s4();
            const promise = new Promise((resolver, reject) => {
                this.resolverHash[id] = { resolver: null, reject, rawResolver: resolver };
            });
            this.worker.postMessage({ id, isRaw: true, code, context });
            return promise;
        });
    }
}
