var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject } from "fw";
import { Store, handle } from "fw-state";
import { StartAction, SubmitInquiryAction, AddFile } from "./actions";
import { InquiryResponseRepository } from "network/inquiry-response-repository";
import { FileRepository } from "network/file-repository";
import { isAnswered } from "shared/form-runtime";
let ContextStore = class ContextStore extends Store {
    constructor(fileRepo, inquiryResponseRepo) {
        super();
        this.fileRepo = fileRepo;
        this.inquiryResponseRepo = inquiryResponseRepo;
    }
    defaultState() {
        return {
            organization: null,
            portal: null,
            forms: [],
            fileHash: {},
            formsUseFullName: {},
            response: null,
            featureFlags: {},
        };
    }
    handleStart(s) {
        const { orgContext } = s;
        if (orgContext == null)
            return;
        this.setState(state => (Object.assign(Object.assign({}, state), { organization: orgContext.Organization, portal: orgContext.InquiryPortal, forms: orgContext.Forms, formsUseFullName: orgContext.FormsUseFullName, featureFlags: orgContext.FeatureFlags })));
    }
    handleSubmitInquiryAction(action) {
        return __awaiter(this, void 0, void 0, function* () {
            action.response.validate();
            const pf = this.state.portal.Forms.find(p => p.PageSlug == action.page);
            if (pf == null)
                return;
            const form = this.state.forms.find(f => f.Id == pf.FormId);
            if (form == null)
                return;
            const getQuestion = (key) => {
                for (let i = 0; i < form.Sections.length; i++) {
                    const section = form.Sections[i];
                    const question = section.Questions.find(q => q.Key == key);
                    if (question != null)
                        return question;
                }
                return null;
            };
            if (action.response.Answers != null && action.pruneAnswers) {
                action.response.Answers = action.response.Answers.filter(a => action.seenQuestionKeys.some(sq => a.QuestionKey == sq) &&
                    isAnswered(getQuestion(a.QuestionKey), a));
            }
            try {
                const response = yield this.inquiryResponseRepo.submit(action.page, action.response.updatedModel(), action.seenQuestionKeys, this.state.portal.Domain);
                this.setState(state => (Object.assign(Object.assign({}, state), { response })));
            }
            catch (err) {
                // try to reapply the validation
                if (err && err.result && err.result.validation_result) {
                    for (const validationResult of err.result.validation_result) {
                        if (validationResult.key == null)
                            continue;
                        const answer = action.response.Answers.find(a => a.QuestionKey == validationResult.key);
                        if (answer != null) {
                            answer.MetaData.ValidationSummary = [];
                            answer.MetaData.ValidationSummary.push({
                                Message: validationResult.message,
                                Path: validationResult.path,
                            });
                            answer.MetaData.IsValid = false;
                        }
                    }
                }
                throw err;
            }
        });
    }
    handleAddFile(af) {
        return __awaiter(this, void 0, void 0, function* () {
            const file = yield this.fileRepo.get(af.id);
            this.state.fileHash = Object.assign(Object.assign({}, this.state.fileHash), { [file.Id]: file });
            this.setState(s => s);
        });
    }
};
__decorate([
    handle(StartAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [StartAction]),
    __metadata("design:returntype", void 0)
], ContextStore.prototype, "handleStart", null);
__decorate([
    handle(SubmitInquiryAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SubmitInquiryAction]),
    __metadata("design:returntype", Promise)
], ContextStore.prototype, "handleSubmitInquiryAction", null);
__decorate([
    handle(AddFile),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [AddFile]),
    __metadata("design:returntype", Promise)
], ContextStore.prototype, "handleAddFile", null);
ContextStore = __decorate([
    inject,
    __metadata("design:paramtypes", [FileRepository,
        InquiryResponseRepository])
], ContextStore);
export { ContextStore };
