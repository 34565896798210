export class StartAction {
    constructor(orgContext) {
        this.orgContext = orgContext;
    }
}
export class AddFile {
    constructor(id) {
        this.id = id;
    }
}
export class SubmitInquiryAction {
    constructor(page, response, seenQuestionKeys, pruneAnswers = true) {
        this.page = page;
        this.response = response;
        this.seenQuestionKeys = seenQuestionKeys;
        this.pruneAnswers = pruneAnswers;
    }
}
