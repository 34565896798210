var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ComponentEventBus, inject, prop } from "@derekpitt/fw";
import { AsYouType, getCountryCallingCode, } from "libphonenumber-js";
import Vue from "vue";
import CountryFlag from "vue-country-flag";
import { FormAnswer, Question } from "../../models";
import { countries } from "../../../../countries/index";
const DEFAULT_COUNTRY = "US";
let PhoneType = class PhoneType {
    constructor(ceb) {
        this.ceb = ceb;
        this.countryCallingCode = '';
        this.dataId = '';
        this.previousCountryCallingCode = '';
        this.selectedCountry = null;
        this.validation = '';
    }
    created() {
        Vue.use(CountryFlag);
    }
    attached() {
        var _a, _b;
        this.dataId = (_a = this.question) === null || _a === void 0 ? void 0 : _a.Key;
        if ((_b = this.answer) === null || _b === void 0 ? void 0 : _b.Text) {
            this.setCountryFromPhone(this.answer.Text);
        }
    }
    set value(value) {
        var _a, _b;
        this.answer.Text = value;
        this.ceb.dispatch("answer-changed", (_b = (_a = this.answer) === null || _a === void 0 ? void 0 : _a.Text) === null || _b === void 0 ? void 0 : _b.trim());
    }
    get value() {
        var _a;
        return (_a = this.answer) === null || _a === void 0 ? void 0 : _a.Text;
    }
    get disabled() {
        return this.readonly;
    }
    get countries() {
        let options = [];
        for (const countryKey in countries) {
            options.push({
                value: countryKey,
                text: countries[countryKey],
                dataId: countryKey,
            });
        }
        return options;
    }
    setCountry(selectedCountry) {
        this.selectedCountry = selectedCountry;
        this.previousCountryCallingCode = this.countryCallingCode;
        if (!!this.selectedCountry) {
            this.countryCallingCode = getCountryCallingCode(this.selectedCountry);
        }
        else {
            this.countryCallingCode = '';
        }
    }
    setCountryFromPhone(phoneNumber) {
        const dynamicPhoneType = new AsYouType();
        dynamicPhoneType.input(phoneNumber);
        const derivedCountry = dynamicPhoneType.getCountry();
        if (!!derivedCountry && (this.selectedCountry !== derivedCountry)) {
            this.setCountry(derivedCountry);
        }
    }
    onKeyUp() {
        if (this.value) {
            if (-1 === this.value.indexOf('+')) {
                this.prependCountryCallingCode(DEFAULT_COUNTRY);
            }
            this.setCountryFromPhone(this.value);
        }
        this.ceb.dispatch("keyup");
    }
    clear() {
        this.answer.Text = '';
        this.setCountry(null);
        this.ceb.dispatch("answer-changed", this.answer.Text);
    }
    prependCountryCallingCode(selectedCountry) {
        var _a;
        this.setCountry(selectedCountry);
        let phoneNumber = ((_a = this.answer) === null || _a === void 0 ? void 0 : _a.Text) || '';
        if (!!phoneNumber) {
            // improve ux: remove user populated country code if exists (before adding it back)
            const countryCodeRegex = new RegExp(`^\\+?${this.countryCallingCode}`);
            phoneNumber = phoneNumber.replace(countryCodeRegex, '');
            // improve ux: remove previous user populated country code if exists (before adding it back)
            if (!!this.previousCountryCallingCode) {
                const previousCountryCodeRegex = new RegExp(`^\\+?${this.previousCountryCallingCode}`);
                phoneNumber = phoneNumber.replace(previousCountryCodeRegex, '');
            }
            // improve ux: remove `+` if exists (before adding it back)
            phoneNumber = phoneNumber.replace(/\+/g, '');
        }
        if (!!this.selectedCountry) {
            this.answer.Text = `+${this.countryCallingCode}${phoneNumber}`;
        }
        else {
            const defaultCountryCallingCode = getCountryCallingCode(DEFAULT_COUNTRY);
            this.answer.Text = `+${defaultCountryCallingCode}${phoneNumber}`;
        }
        this.ceb.dispatch("answer-changed", this.answer.Text);
    }
};
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], PhoneType.prototype, "answer", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], PhoneType.prototype, "ariaLabelledby", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], PhoneType.prototype, "compact", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], PhoneType.prototype, "displayonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Question)
], PhoneType.prototype, "question", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], PhoneType.prototype, "readonly", void 0);
PhoneType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], PhoneType);
export { PhoneType };

PhoneType.__template = require('./phone-type.html');
