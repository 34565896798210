var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject } from "fw";
import { dispatch } from "fw-state";
import { FileGetter, FileUploader, PendingFile } from "uploader";
import { FileRepository } from "network/file-repository";
import { AddFile } from "state/actions";
let FileService = class FileService {
    constructor(fileRepo, fileGetter, fileUploader) {
        this.fileRepo = fileRepo;
        this.fileGetter = fileGetter;
        this.fileUploader = fileUploader;
        this.queue = [];
    }
    getFile() {
        return __awaiter(this, void 0, void 0, function* () {
            const files = yield this.fileGetter.getFiles();
            if (files.length == 0)
                return null;
            const pendingFile = files[0];
            return yield this.getContext(pendingFile);
        });
    }
    getFiles() {
        return __awaiter(this, void 0, void 0, function* () {
            const files = yield this.fileGetter.getFiles();
            if (files.length == 0)
                return null;
            const promises = files.map(f => this.getContext(f));
            return yield Promise.all(promises);
        });
    }
    getContext(pendingFile) {
        return __awaiter(this, void 0, void 0, function* () {
            const file = yield this.fileRepo.post({
                ContextId: "TODO",
                ContextType: "Inquiry",
                Filename: pendingFile.fileName,
                Size: pendingFile.fileSize,
                ContentType: pendingFile.contentType,
            });
            pendingFile.uploadUrl = file.PutUrl;
            dispatch(new AddFile(file.FileId));
            return { pendingFile, file };
        });
    }
    queueUp(pendingFile) {
        return __awaiter(this, void 0, void 0, function* () {
            this.queue.push(pendingFile);
            this.fileUploader.queue(pendingFile);
            yield pendingFile.waitUntilUploaded();
            const idx = this.queue.indexOf(pendingFile);
            if (idx >= 0)
                this.queue.splice(idx, 1);
        });
    }
    enqueue(file) {
        if (file instanceof PendingFile) {
            this.queueUp(file);
        }
        else {
            this.queueUp(file.pendingFile);
        }
    }
};
FileService = __decorate([
    inject,
    __metadata("design:paramtypes", [FileRepository,
        FileGetter,
        FileUploader])
], FileService);
export { FileService };
