var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
let RadioType = class RadioType {
    constructor(ecb) {
        this.ecb = ecb;
        this.value = null;
        this.writeInValue = "";
    }
    attached() {
        if (this.question == null)
            return;
        this.answerChanged();
    }
    answerChanged() {
        const { FormAnswerOption } = this.answer;
        if (FormAnswerOption != null) {
            if (FormAnswerOption.AnswerOptionId == null) { // write in
                this.writeInValue = FormAnswerOption.OptionText;
                this.value = this.getValueForWriteInOption();
            }
            else {
                this.value = FormAnswerOption.AnswerOptionId;
                this.writeInValue = "";
            }
        }
    }
    change() {
        const option = this.question.AnswerOptions.find(o => o.Id == this.value);
        let OptionText = option != null ? option.Label : null;
        const AnswerOptionId = this.value == "writeIn" ? null : this.value;
        if (AnswerOptionId == null && this.writeInValue.trim().length > 0)
            OptionText = this.writeInValue;
        this.answer.FormAnswerOption = this.value != null ? { AnswerOptionId, OptionText } : null;
        this.ecb.dispatch("answer-changed");
    }
    writeInKeyUp() {
        this.value = this.getValueForWriteInOption();
    }
    writeInClick() {
        this.value = this.getValueForWriteInOption();
    }
    clear() {
        this.value = null;
        this.writeInValue = "";
        this.change();
    }
    writeInRadioBtnClick(event) {
        this.writeInInputRef.focus();
        this.value = this.getValueForWriteInOption();
        if (!this.value) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.change();
    }
    getValueForWriteInOption() {
        return this.writeInValue.trim().length > 0 ? "writeIn" : null;
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], RadioType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], RadioType.prototype, "answerOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], RadioType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], RadioType.prototype, "readonly", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], RadioType.prototype, "showOptions", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], RadioType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], RadioType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", String)
], RadioType.prototype, "ariaLabelledby", void 0);
RadioType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], RadioType);
export { RadioType };

RadioType.__template = require('./radio.html');
