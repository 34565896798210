var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject } from "fw";
import { DialogController } from "fw-dialog";
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["Error"] = 0] = "Error";
    NotificationType[NotificationType["Success"] = 1] = "Success";
    NotificationType[NotificationType["Warning"] = 2] = "Warning";
    NotificationType[NotificationType["Default"] = 3] = "Default";
})(NotificationType || (NotificationType = {}));
let NotificationDialog = class NotificationDialog {
    constructor(controller) {
        this.controller = controller;
        this.title = "";
        this.text = "";
        this.type = null;
    }
    attached() {
        this.closeButtonEl.focus();
    }
    activate(args) {
        this.title = args.title;
        this.text = args.text;
        this.type = args.type;
    }
    get containerClass() {
        return "c-notification-dialog-" + NotificationType[this.type];
    }
    get isArrayOfMessages() {
        return Array.isArray(this.text);
    }
    close() {
        this.controller.cancel();
    }
};
NotificationDialog = __decorate([
    inject,
    __metadata("design:paramtypes", [DialogController])
], NotificationDialog);
export { NotificationDialog };

NotificationDialog.__template = require('./notification-dialog.html');
