var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, Network } from "fw";
import { InquiryPortalConfig } from "config/inquiry-portal-config";
let ATS = class ATS {
    constructor(network, config) {
        this.network = network;
        this.config = config;
        this.organizationId = null;
        this.portalId = null;
    }
    configureIds(organizationId, portalId) {
        this.organizationId = organizationId;
        this.portalId = portalId;
    }
    withOrganizationId(d) {
        return Object.assign({}, d, { OrganizationId: this.organizationId });
    }
    withPortalId(d) {
        return Object.assign({}, d, { ApplicantPortalId: this.portalId });
    }
    withOrganizationAndPortalId(d) {
        return Object.assign({}, d, { ApplicantPortalId: this.portalId, OrganizationId: this.organizationId });
    }
    get(url, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.network.get(this.config.apiUrl + url, params);
            return res.body;
        });
    }
    post(url, data, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.network.post(this.config.apiUrl + url, data, params);
            return res.body;
        });
    }
    put(url, data, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.network.put(this.config.apiUrl + url, data, params);
            return res.body;
        });
    }
    // TODO: test these out
    delete(url, params) { return null; }
};
ATS = __decorate([
    inject,
    __metadata("design:paramtypes", [Network, InquiryPortalConfig])
], ATS);
export { ATS };
export function isValidationResult(d) {
    return d.statusCode == 400 && d.result && d.result.validation_result;
}
