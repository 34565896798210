import { Validators, formFor } from "fw-model";
import { InquiryResponse } from "models/inquiry-response";
const { wrap, isEmail, required } = Validators;
const isNullOrEmpty = (str) => str == null || str.trim().length == 0;
export const inquiryResponseFormCreator = formFor(InquiryResponse, s => {
    s.field(a => a.FirstName, "First Name", o => o.if(obj => isNullOrEmpty(obj.FullName), required));
    s.field(a => a.LastName, "Last Name", o => o.if(obj => isNullOrEmpty(obj.FullName), required));
    s.field(a => a.FullName, "Name", o => o.if(obj => isNullOrEmpty(obj.FirstName) && isNullOrEmpty(obj.LastName), required));
    s.requiredField(a => a.EmailAddress, "Email Address", wrap(isEmail));
    s.field(a => a.Answers, "Answers");
});
