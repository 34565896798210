import { ConditionOperator, ConditionFieldType, } from "./models";
export function getQuestion(form, questionKey) {
    for (let i = 0; i < form.Sections.length; i++) {
        const section = form.Sections[i];
        const question = section.Questions.find(q => q.Key == questionKey);
        if (question != null)
            return question;
    }
    return null;
}
const nameAnswerDataKeys = [
    "title",
    "given",
    "middle",
    "family",
    "suffix",
    "full",
];
const addressAnswerDataKeys = [
    "address1",
    "address2",
    "city",
    "region",
    "country",
    "postalCode",
    "location",
];
export function getAnswerHash(answers) {
    const answerHash = {};
    const isWriteInHash = {};
    answers.forEach(a => {
        if (a.Number != null)
            answerHash[a.QuestionKey] = a.Number;
        else if (a.Date != null)
            answerHash[a.QuestionKey] = a.Date;
        else if (a.FormAnswerOption != null) {
            answerHash[a.QuestionKey] = a.FormAnswerOption.OptionText;
            isWriteInHash[a.QuestionKey] = !!a.FormAnswerOption.IsWriteIn;
        }
        else if (a.FormAnswerOptions != null) {
            answerHash[a.QuestionKey] = a.FormAnswerOptions.map(fao => fao.OptionText);
            isWriteInHash[a.QuestionKey] = a.FormAnswerOptions.some(fao => fao.IsWriteIn);
        }
        else if (a.FileId != null)
            answerHash[a.QuestionKey] = a.FileId;
        else if (a.PhoneNumber != null)
            answerHash[a.QuestionKey] = a.PhoneNumber;
        else if (a.Data != null) {
            answerHash[a.QuestionKey] = a.Data;
            if (addressAnswerDataKeys.every(key => a.Data[key] != null)) {
                const parts = [];
                for (const key of ["city", "region", "country"]) {
                    if (a.Data[key] != null)
                        parts.push(a.Data[key]);
                }
                answerHash[a.QuestionKey]["location"] = parts.join(", ");
            }
            else if (nameAnswerDataKeys.every(key => a.Data[key] != null)) {
                const parts = [a.Data["family"], a.Data["given"]];
                if (a.Data["middle"] != null)
                    parts[1] += " " + a.Data["middle"];
                if (a.Data["suffix"] != null)
                    parts.push(a.Data["suffix"]);
                answerHash[a.QuestionKey]["full"] = parts.join(", ");
            }
        }
        else if (a.Rows != null)
            answerHash[a.QuestionKey] = a.Rows;
        else
            answerHash[a.QuestionKey] = a.Text;
        answerHash["isWriteIn"] = isWriteInHash;
    });
    return answerHash;
}
export function calcColumnWidth(options, colWidth, frontPercent = 0) {
    const totalWidth = options.Columns.reduce((prev, cur) => prev + cur.Width, 0);
    let percent = colWidth / totalWidth * 100;
    if (frontPercent > 0) {
        percent *= 1 - (frontPercent / 100);
    }
    return `${percent}%`;
}
export const applyValidation = (answers, validation) => {
    for (const validationResult of validation.validation_result) {
        if (validationResult.key == null)
            continue;
        let answer = answers.find(a => a.QuestionKey == validationResult.key);
        if (answer == null && validationResult.key.indexOf("[") > 0) {
            const [sectionKey, idxStr, answerKey] = validationResult.key.split(/\[|\]\./);
            const section = answers.find(a => a.SectionKey == sectionKey);
            if (section != null) {
                const row = section.SectionAnswers[parseInt(idxStr, 10)];
                if (row != null) {
                    answer = row.Answers.find(a => a.QuestionKey == answerKey);
                }
            }
        }
        if (answer == null && validationResult.key.indexOf(".") > 0) {
            const [sectionKey, rowKey, answerKey] = validationResult.key.split(".");
            const section = answers.find(a => a.SectionKey == sectionKey);
            if (section != null) {
                const row = section.SectionAnswers.find(a => a.RowKey == rowKey);
                if (row != null) {
                    answer = row.Answers.find(a => a.QuestionKey == answerKey);
                }
            }
        }
        if (answer != null) {
            answer.MetaData.ValidationSummary = [];
            answer.MetaData.ValidationSummary.push({
                Message: validationResult.message,
                Path: validationResult.key || validationResult.path,
            });
            answer.MetaData.IsValid = false;
        }
    }
};
export const conditionOperatorsNoValueRequired = [
    ConditionOperator.Empty,
    ConditionOperator.NotEmpty
];
export function getConditionOperators(fieldType) {
    const operators = [
        ConditionOperator.Empty,
        ConditionOperator.NotEmpty
    ];
    switch (fieldType) {
        case ConditionFieldType.Boolean:
            operators.push(ConditionOperator.Equals, ConditionOperator.NotEquals);
            break;
        case ConditionFieldType.Date:
            operators.push(ConditionOperator.Equals, ConditionOperator.NotEquals, ConditionOperator.GreaterThan, ConditionOperator.GreaterThanEquals, ConditionOperator.LessThan, ConditionOperator.LessThanEquals);
            break;
        case ConditionFieldType.Number:
            operators.push(ConditionOperator.Equals, ConditionOperator.NotEquals, ConditionOperator.GreaterThan, ConditionOperator.GreaterThanEquals, ConditionOperator.LessThan, ConditionOperator.LessThanEquals);
            break;
        case ConditionFieldType.String:
            operators.push(ConditionOperator.Equals, ConditionOperator.NotEquals, ConditionOperator.Contains, ConditionOperator.NotContains, ConditionOperator.StartsWith, ConditionOperator.NotStartWith);
            break;
        case ConditionFieldType.Array:
            operators.push(ConditionOperator.Contains, ConditionOperator.NotContains);
            break;
    }
    return operators;
}
const transformFilterData = (data) => {
    if (typeof data === 'undefined')
        return [];
    const tempList = [{ value: "", text: "" }];
    for (const key in data) {
        tempList.push({ value: key, text: data[key] });
    }
    return tempList;
};
export const mergeFilterData = (dataDictionary, value) => {
    var _a;
    if (typeof dataDictionary === 'undefined')
        return [];
    return (!value || ((_a = Object.keys(dataDictionary)) === null || _a === void 0 ? void 0 : _a.includes(value))) ?
        transformFilterData(dataDictionary) :
        transformFilterData(Object.assign(Object.assign({}, dataDictionary), { [value]: value }));
};
