var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, prop, needs, ComponentEventBus } from "@derekpitt/fw";
import { createFrom } from "fw-model";
import { FormAnswer, Form, FormSection, TableSectionRowFormData, } from "../models";
import { DialogService } from "fw-dialog";
import { AddTableSectionRow } from "./add-table-section-row";
import { Question } from "./question";
import { Restrictable } from "./restrictable";
let SectionTable = class SectionTable {
    constructor(dialogServer, ceb) {
        this.dialogServer = dialogServer;
        this.ceb = ceb;
    }
    get canAdd() {
        if (this.section == null)
            return false;
        const { MaxRowCount, MinRowCount, NamedRows, } = this.section.TableSectionOptions;
        if (NamedRows != null && NamedRows.length > 0)
            return false;
        if (MaxRowCount == 0 && MinRowCount == 0)
            return true;
        if (this.answer == null)
            return false;
        if (this.answer.SectionAnswers.length >= MaxRowCount)
            return false;
        return true;
    }
    get canRemove() {
        if (this.section == null)
            return false;
        const { MaxRowCount, MinRowCount, NamedRows, } = this.section.TableSectionOptions;
        if (NamedRows != null && NamedRows.length > 0)
            return false;
        if (MaxRowCount == 0 && MinRowCount == 0)
            return true;
        if (this.answer == null)
            return false;
        if (this.answer.SectionAnswers.length <= MinRowCount)
            return false;
        return true;
    }
    get hasActions() {
        if (this.readonly || this.displayonly)
            return false;
        if (this.section == null)
            return false;
        const { NamedRows } = this.section.TableSectionOptions;
        if (NamedRows != null && NamedRows.length > 0)
            return false;
        return true;
    }
    get isNamed() {
        if (this.section == null)
            return false;
        const { NamedRows } = this.section.TableSectionOptions;
        if (NamedRows != null && NamedRows.length > 0)
            return true;
        return false;
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.openRowDialog();
            if (res.canceled)
                return;
            const newRow = new TableSectionRowFormData();
            newRow.Answers = res.result;
            this.answer.SectionAnswers.push(newRow);
            this.ceb.dispatch("update:answer", this.answer);
            this.ceb.dispatch("answer-changed");
        });
    }
    get rows() {
        if (this.answer == null)
            return [];
        const rows = [];
        for (const a of this.answer.SectionAnswers) {
            const answers = [];
            for (const q of this.section.TableSectionOptions.Columns) {
                // TODO: add support for indexing into complex options
                answers.push(a.Answers.find(aa => aa.QuestionKey == q.FieldPath));
            }
            const namedRow = (this.section.TableSectionOptions.NamedRows || []).find(nr => nr.Key == a.RowKey);
            const allAnswersValid = answers.filter(a => a !== undefined).every(a => a.MetaData.IsValid);
            rows.push({
                answers,
                key: a.RowKey,
                label: namedRow ? namedRow.Name : a.RowKey,
                section_answer: a,
                isInvalid: !allAnswersValid,
            });
        }
        return rows;
    }
    get invalid() {
        return this.rows.some(r => r.isInvalid);
    }
    formatAnswer(answer) {
        return answer.Text;
    }
    getQuestion(answer) {
        if (answer == null)
            return null;
        return this.section.Questions.find(q => q.Key == answer.QuestionKey);
    }
    removeRow(idx) {
        this.answer.SectionAnswers.splice(idx, 1);
        this.ceb.dispatch("update:answer", this.answer);
        this.ceb.dispatch("answer-changed");
    }
    openRowDialog(answers = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const miniForm = createFrom(Form, null);
            miniForm.Key = this.section.TableSectionOptions.Key;
            miniForm.CalculatedFields = [];
            miniForm.Sections = [createFrom(FormSection, null)];
            miniForm.Sections[0].Questions = this.section.Questions;
            return yield this.dialogServer.open(AddTableSectionRow, {
                form: miniForm,
                component: this.component,
                baseAnswers: this.baseAnswers || [],
                answers,
                isNew: answers.length == 0,
                questionOptions: {
                    readonly: this.readonly,
                    fileHash: this.fileHash,
                    fileService: this.fileService,
                    requestCeeb: this.requestCeeb,
                    decryptData: this.decryptData,
                    displayonly: this.displayonly,
                    showValidation: this.showValidation,
                    showOptions: this.showOptions,
                }
            }, { cssClass: "add-section-row" });
        });
    }
    editRow(row) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.openRowDialog(row.answers);
            if (res.canceled)
                return;
            row.section_answer.Answers = res.result;
            this.ceb.dispatch("update:answer", this.answer);
            this.ceb.dispatch("answer-changed");
        });
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Form)
], SectionTable.prototype, "form", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormSection)
], SectionTable.prototype, "section", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], SectionTable.prototype, "component", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], SectionTable.prototype, "answer", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], SectionTable.prototype, "baseAnswers", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], SectionTable.prototype, "readonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], SectionTable.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], SectionTable.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], SectionTable.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], SectionTable.prototype, "decryptData", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], SectionTable.prototype, "displayonly", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], SectionTable.prototype, "showValidation", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], SectionTable.prototype, "showOptions", void 0);
__decorate([
    prop(() => []),
    __metadata("design:type", Array)
], SectionTable.prototype, "restrictions", void 0);
SectionTable = __decorate([
    inject,
    needs(Question, Restrictable),
    __metadata("design:paramtypes", [DialogService,
        ComponentEventBus])
], SectionTable);
export { SectionTable };

SectionTable.__template = require('./section-table.html');
