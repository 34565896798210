var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { prop } from "fw";
export class Icon {
    attached() {
        const hasHiddenValue = this.ariaHidden === false || this.ariaHidden === true;
        if (hasHiddenValue && this.ariaHidden && this.ariaLabel) {
            console.warn("aria-hidden and aria-label cannot both be set.");
        }
    }
    get isHidden() {
        // if label is set then it defaults to false.
        if (this.ariaLabel) {
            return false;
        }
        const hasHiddenValue = this.ariaHidden === false || this.ariaHidden === true;
        return !hasHiddenValue || this.ariaHidden === true;
    }
}
__decorate([
    prop(null),
    __metadata("design:type", String)
], Icon.prototype, "name", void 0);
__decorate([
    prop(null),
    __metadata("design:type", String)
], Icon.prototype, "ariaLabel", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Boolean)
], Icon.prototype, "ariaHidden", void 0);

Icon.__template = require('./icon.html');
