export var PreviewStatus;
(function (PreviewStatus) {
    PreviewStatus[PreviewStatus["None"] = 0] = "None";
    PreviewStatus[PreviewStatus["Ready"] = 1] = "Ready";
    PreviewStatus[PreviewStatus["Sent"] = 2] = "Sent";
    PreviewStatus[PreviewStatus["Complete"] = 3] = "Complete";
    PreviewStatus[PreviewStatus["Error"] = 4] = "Error";
})(PreviewStatus || (PreviewStatus = {}));
export var FileStatus;
(function (FileStatus) {
    FileStatus[FileStatus["Reserved"] = 0] = "Reserved";
    FileStatus[FileStatus["Uploaded"] = 1] = "Uploaded";
    FileStatus[FileStatus["UploadFailed"] = 2] = "UploadFailed";
    FileStatus[FileStatus["Processed"] = 3] = "Processed";
    FileStatus[FileStatus["Error"] = 4] = "Error";
    FileStatus[FileStatus["Infected"] = 5] = "Infected";
})(FileStatus || (FileStatus = {}));
export class File {
}
export var MediaType;
(function (MediaType) {
    MediaType[MediaType["Image"] = 0] = "Image";
    MediaType[MediaType["Video"] = 1] = "Video";
    MediaType[MediaType["Audio"] = 2] = "Audio";
    MediaType[MediaType["Document"] = 3] = "Document";
    MediaType[MediaType["Other"] = 4] = "Other";
    MediaType[MediaType["Model"] = 5] = "Model";
})(MediaType || (MediaType = {}));
export class FilePostResult {
}
