var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { fromClass, fromClassArray } from "fw-model";
import { CEEBSourceFileType, CalculatedFieldDataTypeCode, NumberQuestionType, QuestionScaleMode, QuestionType } from "./enums";
export class ConditionInfo {
    constructor() {
        this.ReferencedFields = [];
    }
}
export class ConditionGroup {
    constructor() {
        this.Operator = ConditionGroupOperator.And;
        this.Conditions = [];
    }
}
export class Condition {
    constructor() {
        this.Field = null;
        this.FieldType = ConditionFieldType.String;
        this.Operator = ConditionOperator.Equals;
        this.ValueIsField = false;
    }
}
export class ConditionExpression {
    constructor() {
        this.Expression = null;
    }
}
export var ConditionGroupOperator;
(function (ConditionGroupOperator) {
    ConditionGroupOperator["And"] = "And";
    ConditionGroupOperator["Or"] = "Or";
})(ConditionGroupOperator || (ConditionGroupOperator = {}));
export var ConditionOperator;
(function (ConditionOperator) {
    ConditionOperator["Equals"] = "Equals";
    ConditionOperator["NotEquals"] = "NotEquals";
    ConditionOperator["GreaterThan"] = "GreaterThan";
    ConditionOperator["GreaterThanEquals"] = "GreaterThanEquals";
    ConditionOperator["LessThan"] = "LessThan";
    ConditionOperator["LessThanEquals"] = "LessThanEquals";
    ConditionOperator["Contains"] = "Contains";
    ConditionOperator["NotContains"] = "NotContains";
    ConditionOperator["Empty"] = "Empty";
    ConditionOperator["NotEmpty"] = "NotEmpty";
    ConditionOperator["StartsWith"] = "StartsWith";
    ConditionOperator["NotStartWith"] = "NotStartsWith";
})(ConditionOperator || (ConditionOperator = {}));
export var ConditionFieldType;
(function (ConditionFieldType) {
    ConditionFieldType["Boolean"] = "Boolean";
    ConditionFieldType["Date"] = "Date";
    ConditionFieldType["Number"] = "Number";
    ConditionFieldType["String"] = "String";
    ConditionFieldType["Object"] = "Object";
    ConditionFieldType["Array"] = "Array";
    ConditionFieldType["Phone"] = "Phone";
})(ConditionFieldType || (ConditionFieldType = {}));
export class CalculatedField {
    constructor() {
        this.Label = null;
        this.Key = null;
        this.IsKeyEdited = false;
        this.Expression = null;
        this.DataType = CalculatedFieldDataTypeCode.String;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", ConditionInfo)
], CalculatedField.prototype, "BooleanExpressionInfo", void 0);
export class TableColumn {
    constructor() {
        this.Label = null;
        this.Width = null;
    }
}
export class QuestionTableOptions {
    constructor() {
        this.RowNames = [];
        this.MinRowCount = null;
        this.MaxRowCount = null;
    }
}
__decorate([
    fromClassArray(TableColumn),
    __metadata("design:type", Array)
], QuestionTableOptions.prototype, "Columns", void 0);
export class FormQuestionScaleValue {
    constructor() {
        this.Label = null;
        this.Value = null;
    }
}
export class FormQuestionScaleGroupItem {
    constructor() {
        this.Label = null;
        this.Key = null;
        this.IsKeyEdited = false;
    }
}
export class QuestionScaleOptions {
    constructor() {
        this.Mode = QuestionScaleMode.Range;
    }
}
__decorate([
    fromClassArray(FormQuestionScaleValue),
    __metadata("design:type", Array)
], QuestionScaleOptions.prototype, "Values", void 0);
export class QuestionScaleGroupOptions {
    constructor() {
        this.Mode = QuestionScaleMode.Range;
    }
}
__decorate([
    fromClassArray(FormQuestionScaleValue),
    __metadata("design:type", Array)
], QuestionScaleGroupOptions.prototype, "Values", void 0);
__decorate([
    fromClassArray(FormQuestionScaleGroupItem),
    __metadata("design:type", Array)
], QuestionScaleGroupOptions.prototype, "Items", void 0);
export class QuestionNumberOptions {
    constructor() {
        this.Type = NumberQuestionType.Integer;
    }
}
export class QuestionCeebOptions {
    constructor() {
        this.FileType = CEEBSourceFileType.College;
    }
}
export class QuestionOptions {
    constructor() {
        this.MaxLength = null;
        this.AllowWriteIn = null;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", QuestionTableOptions)
], QuestionOptions.prototype, "Table", void 0);
__decorate([
    fromClass,
    __metadata("design:type", QuestionScaleOptions)
], QuestionOptions.prototype, "Scale", void 0);
__decorate([
    fromClass,
    __metadata("design:type", QuestionScaleGroupOptions)
], QuestionOptions.prototype, "ScaleGroup", void 0);
__decorate([
    fromClass,
    __metadata("design:type", QuestionNumberOptions)
], QuestionOptions.prototype, "Number", void 0);
__decorate([
    fromClass,
    __metadata("design:type", QuestionCeebOptions)
], QuestionOptions.prototype, "Ceeb", void 0);
export class AnswerOption {
    constructor() {
        this.Id = null;
        this.Label = null;
        this.Condition = null;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", ConditionInfo)
], AnswerOption.prototype, "ConditionInfo", void 0);
export class Question {
    constructor() {
        this.Key = null;
        this.Label = null;
        this.Text = null;
        this.Help = null;
        this.IsRequired = false;
        this.IsIndexed = false;
        this.Type = QuestionType.ShortText;
        this.Condition = null;
        this.ContactFieldKey = null;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", ConditionInfo)
], Question.prototype, "ConditionInfo", void 0);
__decorate([
    fromClass,
    __metadata("design:type", QuestionOptions)
], Question.prototype, "Options", void 0);
__decorate([
    fromClassArray(AnswerOption),
    __metadata("design:type", Array)
], Question.prototype, "AnswerOptions", void 0);
export class FormMetaData {
}
export class TableSectionRow {
    constructor() {
        this.Key = null;
        this.Name = null;
    }
}
export class TableSectionColumn {
    constructor() {
        this.FieldPath = null;
        this.Label = null;
        this.Width = null;
    }
}
export class TableSectionOptions {
    constructor() {
        this.Key = null;
        this.IsKeyEdited = false;
        this.MinRowCount = 0;
        this.MaxRowCount = 10;
    }
}
__decorate([
    fromClassArray(TableSectionRow),
    __metadata("design:type", Array)
], TableSectionOptions.prototype, "NamedRows", void 0);
__decorate([
    fromClassArray(TableSectionColumn),
    __metadata("design:type", Array)
], TableSectionOptions.prototype, "Columns", void 0);
export class FormSection {
    constructor() {
        this.Title = null;
        this.Introduction = null;
        this.Condition = null;
        this.IsTableSection = false;
        this.IsNew = false;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", ConditionInfo)
], FormSection.prototype, "ConditionInfo", void 0);
__decorate([
    fromClassArray(Question),
    __metadata("design:type", Array)
], FormSection.prototype, "Questions", void 0);
__decorate([
    fromClass,
    __metadata("design:type", TableSectionOptions)
], FormSection.prototype, "TableSectionOptions", void 0);
export class Form {
    constructor() {
        this.Name = null;
        this.Key = null;
    }
}
__decorate([
    fromClass,
    __metadata("design:type", FormMetaData)
], Form.prototype, "MetaData", void 0);
__decorate([
    fromClassArray(FormSection),
    __metadata("design:type", Array)
], Form.prototype, "Sections", void 0);
__decorate([
    fromClassArray(CalculatedField),
    __metadata("design:type", Array)
], Form.prototype, "CalculatedFields", void 0);
export class ValidationItem {
}
export class FormAnswerMetaData {
    constructor() {
        this.IsValid = null;
        this.ValidationSummary = [];
    }
}
__decorate([
    fromClassArray(ValidationItem),
    __metadata("design:type", Array)
], FormAnswerMetaData.prototype, "ValidationSummary", void 0);
export class TableSectionRowFormData {
    constructor() {
        this.RowKey = null;
        this.Answers = [];
        // TODO: calc fields
    }
}
export class FormAnswer {
    constructor() {
        this.SectionKey = null;
        this.QuestionKey = null;
        this.Text = null;
        this.Number = null;
        this.PhoneNumber = null;
        this.FormAnswerOptions = null;
        this.FormAnswerOption = null;
        this.Rows = null;
        this.Data = null;
        this.Date = null;
        this.FileId = null;
        this.IsEncrypted = false;
        this.MetaData = new FormAnswerMetaData();
    }
}
__decorate([
    fromClassArray(TableSectionRowFormData),
    __metadata("design:type", Array)
], FormAnswer.prototype, "SectionAnswers", void 0);
__decorate([
    fromClass,
    __metadata("design:type", FormAnswerMetaData)
], FormAnswer.prototype, "MetaData", void 0);
/* need to explore this more...
export interface Opener {
  open<T>(component: makerOf<any>, params: any): Promise<{ canceled: boolean, result: T }>;
}
*/
