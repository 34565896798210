var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { prop, needs, ComponentEventBus, inject } from "@derekpitt/fw";
import { Form, Question as QuestionModel, FormAnswer } from "../models";
import { QuestionType } from "../enums";
import { validate } from "../validation";
import * as Types from "./types/index";
let uniqueId = 0;
let Question = class Question {
    constructor(ceb) {
        this.ceb = ceb;
        this.questionType = null;
        this.uniqueId = uniqueId++;
    }
    makeId() {
        return `${this.question.Type}-${this.uniqueId}`;
    }
    applicationStepChanged() { this.attached(); }
    questionChanged() { this.computeQuestionType(); }
    attached() {
        this.computeQuestionType();
    }
    computeQuestionType() {
        if (this.question == null)
            return;
        switch (this.question.Type) {
            case QuestionType.ShortText:
            case QuestionType.EmailAddress:
            case QuestionType.URL:
            case QuestionType.Date:
                this.questionType = "short-text-type";
                break;
            case QuestionType.PhoneNumber:
                this.questionType = "phone-type";
                break;
            case QuestionType.LongText:
                this.questionType = "long-text-type";
                break;
            case QuestionType.DropDown:
                this.questionType = "dropdown-type";
                break;
            case QuestionType.RadioButton:
                this.questionType = "radio-type";
                break;
            case QuestionType.CheckBoxList:
                this.questionType = "checkbox-type";
                break;
            case QuestionType.File:
                this.questionType = "file-type";
                break;
            case QuestionType.Scale:
                this.questionType = "scale-type";
                break;
            case QuestionType.ScaleGroup:
                this.questionType = "scale-group-type";
                break;
            case QuestionType.Table:
                this.questionType = "table-type";
                break;
            case QuestionType.Number:
                this.questionType = "number-type";
                break;
            case QuestionType.Address:
                this.questionType = "address-type";
                break;
            case QuestionType.CEEBCode:
                this.questionType = "ceeb-type";
                break;
            case QuestionType.Name:
                this.questionType = "name-type";
                break;
            case QuestionType.Encrypted:
                this.questionType = "encrypted-type";
                break;
            default: break;
        }
    }
    validateAnswer() {
        validate(this.form, this.question, this.answer);
        this.ceb.dispatch("answer-changed");
        return true;
    }
    resolveAnswerOptions() {
        return this.computedAnswerOptions == null ? this.question.AnswerOptions : this.computedAnswerOptions.map(c => this.question.AnswerOptions[c.index]);
    }
    get isInvalid() {
        return this.answer != null && this.answer.MetaData.IsValid === false && this.showValidation;
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Form)
], Question.prototype, "form", void 0);
__decorate([
    prop(null),
    __metadata("design:type", QuestionModel)
], Question.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], Question.prototype, "computedAnswerOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], Question.prototype, "answer", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], Question.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], Question.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], Question.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], Question.prototype, "decryptData", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], Question.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], Question.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], Question.prototype, "compact", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], Question.prototype, "showValidation", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], Question.prototype, "showOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], Question.prototype, "ariaLabelledby", void 0);
Question = __decorate([
    needs(Types.ShortTextType, Types.DropdownType, Types.LongTextType, Types.RadioType, Types.CheckboxType, Types.FileType, Types.ScaleType, Types.ScaleGroupType, Types.TableType, Types.NumberType, Types.NameType, Types.AddressType, Types.CeebType, Types.EncryptedType, Types.PhoneType),
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], Question);
export { Question };

Question.__template = require('./question.html');
