var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Form, field, Validators } from "fw-model";
const { required } = Validators;
export class UnlistedCeebCodeForm extends Form {
    constructor() {
        super(...arguments);
        this.SchoolName = null;
        this.City = null;
        this.Country = null;
        this.Region = null;
        this.Type = "0000";
    }
}
__decorate([
    field("School Name", required),
    __metadata("design:type", String)
], UnlistedCeebCodeForm.prototype, "SchoolName", void 0);
__decorate([
    field("City", required),
    __metadata("design:type", String)
], UnlistedCeebCodeForm.prototype, "City", void 0);
__decorate([
    field("Country", required),
    __metadata("design:type", String)
], UnlistedCeebCodeForm.prototype, "Country", void 0);
__decorate([
    field("State/Region", required),
    __metadata("design:type", String)
], UnlistedCeebCodeForm.prototype, "Region", void 0);
__decorate([
    field("Type", required),
    __metadata("design:type", String)
], UnlistedCeebCodeForm.prototype, "Type", void 0);
