var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { bootstrap, ContainerInstance } from "fw";
import { dispatch, setupStores } from "fw-state";
import { InquiryPortalConfig } from "config/inquiry-portal-config";
import { App } from "views/app";
import { AppRepository } from "network/app-repository";
import { ATS } from "network/ats";
import { ContextStore } from "state/context";
import { StartAction } from "state/actions";
import { registerComponents } from "./bootstrap-components";
import htmlWithScript from "../../directives/html-with-script";
import "scss/all.scss";
bootstrap((c) => __awaiter(void 0, void 0, void 0, function* () {
    const config = yield c.withConfig(InquiryPortalConfig, "inquiry-portal-config.json");
    c.useVuePlugin(htmlWithScript);
    if (localStorage && localStorage["apiUrl"])
        config.apiUrl = localStorage["apiUrl"];
    registerComponents(c);
    configureStores();
    // get the context here..
    try {
        const portalContext = yield getPortalContext();
        yield dispatch(new StartAction(portalContext));
        document.title = portalContext.InquiryPortal.Name;
        const ats = ContainerInstance.get(ATS);
        ats.configureIds(portalContext.Organization.Id, portalContext.InquiryPortal.Id);
        c.startWith(App);
    }
    catch (err) {
        c.startWith(App);
    }
}));
function configureStores() {
    setupStores(ContainerInstance, ContextStore);
}
function getPortalContext() {
    return __awaiter(this, void 0, void 0, function* () {
        const appRepo = ContainerInstance.get(AppRepository);
        let host = window.location.hostname == "localhost"
            ? "inquiry-portal.slideroom-auto.net"
            : window.location.hostname;
        if (localStorage && localStorage["inquiryPortalUrl"]) {
            host = localStorage["inquiryPortalUrl"];
        }
        return yield appRepo.organizationContext(host);
    });
}
