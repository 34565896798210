var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, NetworkException } from "fw";
import { DialogController } from "fw-dialog";
import { countries, regions } from "../../../../countries/index";
import { CEEBSourceFileType } from "models/ceeb-result";
import { CeebRepository } from "network/ceeb-repository";
import { UnlistedCeebCodeForm } from "forms/unlisted-ceeb-code";
import { Notification } from "service/notification";
const PAGE_SIZE = 20;
let CeebSearch = class CeebSearch {
    constructor(controller, lookupRepo, notifier) {
        this.controller = controller;
        this.lookupRepo = lookupRepo;
        this.notifier = notifier;
        this.location = "";
        this.searching = false;
        this.didSearch = false;
        this.searchTerm = "";
        this.searchResults = [];
        this.hasMore = false;
        this.showUnlisted = false;
        this.question = null;
        this.unlistedCeebCodeForm = new UnlistedCeebCodeForm();
        this.countries = [];
        this.regions = null;
        this.fileType = CEEBSourceFileType.HighSchoolAndScholarship;
        this.pageNumber = 1;
    }
    activate(data) {
        if (!data || !data.question) {
            return;
        }
        this.populateCountryDropdown();
        this.populateRegionDropdown();
        this.fileType = data.question.Options.Ceeb.FileType;
        this.question = data.question;
    }
    cancel() {
        this.controller.cancel();
    }
    search() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.showUnlisted = false;
            if (this.searchTerm.trim().length < 2 && this.location.trim().length < 2) {
                return;
            }
            this.searching = true;
            this.didSearch = false;
            try {
                this.pageNumber = 1;
                const res = yield this.fetchCeeb();
                const { Total, Documents } = res;
                this.searchResults = Documents;
                this.handleShowMore(Total);
            }
            catch (error) {
                let message = "Error loading ceeb results";
                if (error instanceof NetworkException && ((_a = error.result) === null || _a === void 0 ? void 0 : _a.Message)) {
                    message += `: ${(_b = error.result) === null || _b === void 0 ? void 0 : _b.Message}`;
                }
                this.notifier.error(message);
            }
            finally {
                this.searching = false;
                this.didSearch = true;
            }
        });
    }
    select(ceebCode) {
        this.controller.ok({
            address: ceebCode.Address,
            city: ceebCode.City,
            code: ceebCode.Code,
            country: ceebCode.Country,
            name: ceebCode.Name,
            postalCode: ceebCode.PostalCode,
            region: ceebCode.Region,
        });
    }
    confirmUnlisted() {
        try {
            this.unlistedCeebCodeForm.validate();
        }
        catch (_a) {
            this.notifier.error("Field validation failed");
        }
        if (!this.unlistedCeebCodeForm.isInvalid) {
            this.controller.ok({
                address: null,
                city: this.unlistedCeebCodeForm.City,
                code: this.unlistedCeebCodeForm.Type,
                country: this.unlistedCeebCodeForm.Country,
                name: this.unlistedCeebCodeForm.SchoolName,
                postalCode: null,
                region: this.unlistedCeebCodeForm.Region,
            });
        }
    }
    selectUnlisted() {
        this.showUnlisted = true;
    }
    showMore() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.fetchCeeb();
                const { Total, Documents } = res;
                this.searchResults.push(...Documents);
                this.handleShowMore(Total);
            }
            catch (error) {
                let message = "Error loading ceeb results";
                if (error instanceof NetworkException && ((_a = error.result) === null || _a === void 0 ? void 0 : _a.Message)) {
                    message += `: ${(_b = error.result) === null || _b === void 0 ? void 0 : _b.Message}`;
                }
                this.notifier.error(message);
            }
        });
    }
    handleShowMore(total) {
        this.hasMore = PAGE_SIZE * this.pageNumber >= total ? false : true;
        this.pageNumber++;
    }
    populateCountryDropdown() {
        const countryList = [{ value: "", text: "" }];
        for (const countryKey in countries) {
            countryList.push({ value: countryKey, text: countries[countryKey] });
        }
        this.countries = countryList;
    }
    populateRegionDropdown() {
        const regionsWithCountryCode = {};
        for (const countryKey in regions) {
            const regionList = [{ value: "", text: "" }];
            for (const regionKey in regions[countryKey]) {
                regionList.push({ value: regionKey, text: regions[countryKey][regionKey] });
            }
            regionsWithCountryCode[countryKey] = regionList;
        }
        this.regions = regionsWithCountryCode;
    }
    fetchCeeb() {
        return this.lookupRepo.searchCeeb(this.fileType, null, this.getQuery(), null, PAGE_SIZE, this.pageNumber);
    }
    getQuery() {
        const queries = [];
        const term = this.searchTerm.trim();
        if (term.length > 0) {
            queries.push(term);
        }
        const location = this.location.trim();
        if (location.length > 0) {
            queries.push(`(city:(${location}) region:(${location}) country:(${location}))`);
        }
        return queries.join(" AND ");
    }
};
CeebSearch = __decorate([
    inject,
    __metadata("design:paramtypes", [DialogController,
        CeebRepository,
        Notification])
], CeebSearch);
export { CeebSearch };

CeebSearch.__template = require('./ceeb-search.html');
