var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var FormWithAnswers_1;
import { inject, needs, prop, ComponentEventBus } from "@derekpitt/fw";
import { FormResolverService } from "../form-resolver-service";
import { FormAnswer, Form, TableSectionRowFormData, } from "../models";
import { Question } from "./question";
import { validate } from "../validation";
import { SectionTable } from "./section-table";
import { Restrictable } from "./restrictable";
let uniqueId = 0;
const rx_url = new RegExp([
    "((?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?",
    "(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)",
    "(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])",
    "(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])",
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}",
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|",
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)",
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*",
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?)",
].join(""), "gi");
let FormWithAnswers = FormWithAnswers_1 = class FormWithAnswers {
    constructor(ceb, formResolver) {
        this.ceb = ceb;
        this.formResolver = formResolver;
        this.resolved = null;
        this.myself = FormWithAnswers_1;
        this.answerHash = {};
        // maybe don't need these for everything...
        this.calcFieldHash = {};
        this.calcFields = [];
        this.uniqueId = uniqueId++;
    }
    uniqueIdPrefix() {
        return `${this.form.Key}${this.uniqueId}-`;
    }
    setupAnswerHash() {
        const tempAnswerHash = {};
        if (this.answers != null) {
            this.answers.forEach(a => {
                if (a.QuestionKey != null) {
                    tempAnswerHash[a.QuestionKey] = a;
                }
                else if (a.SectionKey != null) {
                    tempAnswerHash[a.SectionKey] = a;
                }
            });
        }
        if (this.form == null)
            return tempAnswerHash;
        this.form.Sections.forEach(section => {
            if (section.IsTableSection) {
                const answer = new FormAnswer();
                const { Key, MinRowCount, NamedRows, } = section.TableSectionOptions;
                answer.SectionKey = Key;
                answer.SectionAnswers = [];
                // TODO: we need to handle when form version changes and we update the row
                // count and or the named rows...
                const currentAnswer = tempAnswerHash[Key];
                const resolveAnswers = (row) => {
                    for (const question of section.Questions) {
                        if (row.Answers.find(a => a.QuestionKey == question.Key) == null) {
                            const newAnswer = new FormAnswer();
                            newAnswer.QuestionKey = question.Key;
                            if (this.showValidation)
                                validate(this.form, question, newAnswer);
                            row.Answers.push(newAnswer);
                        }
                    }
                };
                if (NamedRows.length > 0) {
                    for (const nr of NamedRows) {
                        const newRow = new TableSectionRowFormData();
                        newRow.Answers = [];
                        newRow.RowKey = nr.Key;
                        answer.SectionAnswers.push(newRow);
                        if (currentAnswer != null) {
                            const currentSectionAnswer = currentAnswer.SectionAnswers.find(aa => aa.RowKey == nr.Key);
                            if (currentSectionAnswer == null)
                                currentAnswer.SectionAnswers.push(newRow);
                        }
                    }
                }
                else if (MinRowCount > 0) {
                    for (let i = 0; i < MinRowCount; i++) {
                        const newRow = new TableSectionRowFormData();
                        newRow.Answers = [];
                        answer.SectionAnswers.push(newRow);
                    }
                }
                if (currentAnswer == null) {
                    tempAnswerHash[Key] = answer;
                }
                for (const sa of tempAnswerHash[Key].SectionAnswers) {
                    resolveAnswers(sa);
                }
            }
            else {
                section.Questions.forEach(q => {
                    const answer = new FormAnswer();
                    answer.QuestionKey = q.Key;
                    if (this.showValidation)
                        validate(this.form, q, answer);
                    if (tempAnswerHash[q.Key] == null) {
                        tempAnswerHash[q.Key] = answer;
                    }
                });
            }
        });
        this.answerHash = tempAnswerHash;
    }
    formChanged() {
        this.created();
    }
    answersChanged() {
        this.setupAnswerHash();
    }
    answerChanged(dispatchAnswerChanged = true) {
        return __awaiter(this, void 0, void 0, function* () {
            // re eval the question list
            const { sections, calculatedValues } = yield this.formResolver.resolveForm(this.form, this.answerHash, this.baseAnswers, this.programPropertiesHash);
            const seenQuestionKeys = [];
            const answers = [];
            sections.forEach(s => {
                const section = this.form.Sections[s.sectionIndex];
                if (section.IsTableSection) {
                    // need more
                    const answer = this.answerHash[section.TableSectionOptions.Key];
                    const prefix = section.TableSectionOptions.Key;
                    const isNamed = section.TableSectionOptions.NamedRows.length > 0;
                    answer.SectionAnswers.forEach((a, rowIndex) => {
                        let key = prefix;
                        const rowsPrefix = ".rows";
                        if (isNamed) {
                            key += rowsPrefix + "." + a.RowKey;
                        }
                        else {
                            key += rowsPrefix + "[" + rowIndex + "]";
                        }
                        // TODO: something with row keys...
                        a.Answers.forEach(aa => {
                            const question = section.Questions.find(q => q.Key == aa.QuestionKey);
                            if (question != null) {
                                seenQuestionKeys.push(key + "." + aa.QuestionKey);
                            }
                        });
                    });
                    answers.push(answer);
                }
                else {
                    s.questions.forEach(q => {
                        const question = section.Questions[q.index];
                        seenQuestionKeys.push(question.Key);
                        answers.push(this.answerHash[question.Key]);
                    });
                }
            });
            console.log("new answers", answers, seenQuestionKeys);
            this.ceb.dispatch("update:answers", answers);
            this.ceb.dispatch("update:seenKeys", seenQuestionKeys);
            this.resolved = sections;
            this.calcFields = calculatedValues;
            this.ceb.dispatch("calculated-change", calculatedValues);
            if (dispatchAnswerChanged) {
                this.ceb.dispatch("answer-changed");
            }
        });
    }
    created() {
        if (this.form == null)
            return;
        this.setupAnswerHash();
        const calcFieldHash = {};
        this.form.CalculatedFields.forEach(cf => (calcFieldHash[cf.Key] = cf));
        this.calcFieldHash = calcFieldHash;
        this.answerChanged(false);
    }
    linkify(text) {
        return text.replace(rx_url, '<a href="$1" target="_blank">$1</a>');
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Form)
], FormWithAnswers.prototype, "form", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], FormWithAnswers.prototype, "answers", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], FormWithAnswers.prototype, "seenKeys", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], FormWithAnswers.prototype, "baseAnswers", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], FormWithAnswers.prototype, "readonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], FormWithAnswers.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], FormWithAnswers.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], FormWithAnswers.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], FormWithAnswers.prototype, "decryptData", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], FormWithAnswers.prototype, "displayonly", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], FormWithAnswers.prototype, "showValidation", void 0);
__decorate([
    prop(true),
    __metadata("design:type", Boolean)
], FormWithAnswers.prototype, "showOptions", void 0);
__decorate([
    prop(() => []),
    __metadata("design:type", Array)
], FormWithAnswers.prototype, "restrictions", void 0);
__decorate([
    prop(undefined),
    __metadata("design:type", Object)
], FormWithAnswers.prototype, "programPropertiesHash", void 0);
FormWithAnswers = FormWithAnswers_1 = __decorate([
    inject,
    needs(Question, SectionTable, Restrictable),
    __metadata("design:paramtypes", [ComponentEventBus,
        FormResolverService])
], FormWithAnswers);
export { FormWithAnswers };

FormWithAnswers.__template = require('./form-with-answers.html');
