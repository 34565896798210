var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { prop, inject, ComponentEventBus } from "fw";
let uniqueId = 0;
let TextField = class TextField {
    constructor(ceb) {
        this.ceb = ceb;
        this.uniqueId = uniqueId++;
        this.isFocused = false;
        this.elementId = "";
    }
    labelChanged() {
        this.elementId = `${this.label ? this.label.replace(/\s/g, '') : ""}-tf-${this.uniqueId}`;
    }
    onFocus() { this.isFocused = true; }
    onBlur() { this.isFocused = false; }
    onInput(value) {
        //this.componentEventBus.updateModel(value);
        this.ceb.dispatch("update:value", value);
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextField.prototype, "label", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextField.prototype, "value", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextField.prototype, "validation", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextField.prototype, "type", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextField.prototype, "maxlength", void 0);
TextField = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], TextField);
export { TextField };

TextField.__template = require('./text-field.html');
