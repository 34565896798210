var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject } from "fw";
import { ContextStore } from "state/context";
import { DefaultView } from "./default-view";
import { CompleteView } from "./complete-view";
import { FormView } from "./form-view";
const loadCss = (cssString) => {
    const s = document.createElement("style");
    s.innerHTML = cssString;
    document.getElementsByTagName("head")[0].appendChild(s);
};
let App = class App {
    constructor(contextStore) {
        this.contextStore = contextStore;
        loadCss(this.contextStore.state.portal.MetaData.ScopedCss);
        loadCss(this.contextStore.state.portal.Content.GlobalCss);
    }
    registerRoutes(r) {
        r.add("", DefaultView);
        r.add("complete", CompleteView);
        r.add(":key", FormView);
    }
};
App = __decorate([
    inject,
    __metadata("design:paramtypes", [ContextStore])
], App);
export { App };

App.__template = require('./app.html');
