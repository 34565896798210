var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
var ProcessStatusTypeCode;
(function (ProcessStatusTypeCode) {
    ProcessStatusTypeCode[ProcessStatusTypeCode["None"] = 0] = "None";
    ProcessStatusTypeCode[ProcessStatusTypeCode["Pending"] = 1] = "Pending";
    ProcessStatusTypeCode[ProcessStatusTypeCode["Processed"] = 2] = "Processed";
    ProcessStatusTypeCode[ProcessStatusTypeCode["Error"] = 3] = "Error";
    ProcessStatusTypeCode[ProcessStatusTypeCode["Rejected"] = 4] = "Rejected";
})(ProcessStatusTypeCode || (ProcessStatusTypeCode = {}));
let FileType = class FileType {
    constructor(ecb) {
        this.ecb = ecb;
        this.pendingFile = null;
    }
    get fileFromHash() {
        if (this.answer != null && this.fileHash != null) {
            return this.fileHash[this.answer.FileId];
        }
        return null;
    }
    get fileName() {
        if (this.fileFromHash) {
            return this.fileFromHash.ClientFileName;
        }
        if (this.pendingFile != null)
            return this.pendingFile.fileName;
        return null;
    }
    get fileUrl() {
        if (this.fileFromHash) {
            return this.fileFromHash.MetaData.Original.EphemeralUrl;
        }
        return null;
    }
    get fileState() {
        console.log("fileState", this.answer, this.pendingFile, this.fileHash, this.fileUrl);
        if (!this.readonly && this.answer && this.answer.FileId == null) {
            if (this.pendingFile != null) {
                return "uploading";
            }
            return "show-upload";
        }
        if (this.fileFromHash) {
            switch (this.fileFromHash.MetaData.Status) {
                case ProcessStatusTypeCode.Processed:
                    return "file";
                //late doc delivery
                case ProcessStatusTypeCode.None:
                    return "uploading";
                default:
                    return "processing";
            }
        }
        if (this.pendingFile != null) {
            if (this.fileUrl != null)
                return "processing";
            return "uploading";
        }
        if (this.fileUrl != null) {
            return "file";
        }
        // if we get to here, that means that
        // they uploaded a file, it saved the
        // answer to localstorage, but never saved
        // the form.. so if it isn't readonly, lets
        // show the upload form again..
        if (!this.readonly) {
            return "show-upload";
        }
        return "none";
    }
    chooseFile() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const file = yield this.fileService.getFile();
                if (file == null)
                    return;
                this.pendingFile = file.pendingFile;
                yield this.fileService.enqueue(file);
                yield file.pendingFile.waitUntilUploaded();
                this.answer.FileId = file.file.FileId;
                this.change();
            }
            catch (err) {
                this.pendingFile = null;
            }
        });
    }
    change() {
        this.ecb.dispatch("answer-changed");
    }
    clear() {
        this.answer.FileId = null;
        this.pendingFile = null;
        this.change();
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], FileType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], FileType.prototype, "answer", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], FileType.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], FileType.prototype, "fileService", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], FileType.prototype, "readonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], FileType.prototype, "ariaLabelledBy", void 0);
FileType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], FileType);
export { FileType };

FileType.__template = require('./file.html');
