var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
import { QuestionType } from "../../enums";
let ShortTextType = class ShortTextType {
    constructor(ecb) {
        this.ecb = ecb;
    }
    get maxLength() {
        if (this.question == null)
            return 80;
        switch (this.question.Type) {
            case QuestionType.ShortText:
                return this.question.Options.MaxLength || 80;
            case QuestionType.Date:
                return 11;
            case QuestionType.URL:
                return 1024;
        }
    }
    get placeholderText() {
        if (this.question == null)
            return null;
        if (this.question.Type == QuestionType.Date) {
            return "mm/dd/yyyy";
        }
        else {
            return null;
        }
    }
    change() {
        // clear out the date so the api doesn't get confused as to what it should take
        if (this.question.Type == QuestionType.Date) {
            this.answer.Date = null;
        }
        this.ecb.dispatch("answer-changed");
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], ShortTextType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], ShortTextType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], ShortTextType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], ShortTextType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], ShortTextType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ShortTextType.prototype, "ariaLabelledby", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ShortTextType.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ShortTextType.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ShortTextType.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], ShortTextType.prototype, "showOptions", void 0);
ShortTextType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], ShortTextType);
export { ShortTextType };

ShortTextType.__template = require('./short-text.html');
