var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop } from "@derekpitt/fw";
import { Form, FormSection, Question } from "../models";
let Restrictable = class Restrictable {
    constructor() { }
    get entireFormPath() {
        if (this.form == null)
            return null;
        return `${this.form.Key}.*`;
    }
    path() {
        if (!this.form || !this.form.Key)
            return null;
        if (!this.question)
            return this.entireFormPath;
        const sectionKey = this.section && this.section.IsTableSection ?
            `${this.section.TableSectionOptions.Key}.rows.` : "";
        return `${this.form.Key}.${sectionKey}${this.question.Key}`;
    }
    get isRestricted() {
        const entireFormPath = this.entireFormPath;
        return this.restrictions && !!this.restrictions.find(path => path == this.path() || path == entireFormPath);
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Form)
], Restrictable.prototype, "form", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormSection)
], Restrictable.prototype, "section", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Question)
], Restrictable.prototype, "question", void 0);
__decorate([
    prop(() => []),
    __metadata("design:type", Array)
], Restrictable.prototype, "restrictions", void 0);
__decorate([
    prop("div"),
    __metadata("design:type", String)
], Restrictable.prototype, "element", void 0);
Restrictable = __decorate([
    inject,
    __metadata("design:paramtypes", [])
], Restrictable);
export { Restrictable };

Restrictable.__template = require('./restrictable.html');
