var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { prop } from "fw";
import { Form } from "fw-model";
export class TextFormField {
    constructor() {
        this.name = "";
    }
    attached() {
        this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
    }
}
__decorate([
    prop(null),
    __metadata("design:type", Form)
], TextFormField.prototype, "form", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextFormField.prototype, "field", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], TextFormField.prototype, "type", void 0);

TextFormField.__template = require('./text-form-field.html');
