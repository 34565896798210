var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, needs, Navigator, NetworkException } from "fw";
import { dispatch } from "fw-state";
import { ContextStore } from "state/context";
import { InquiryForm } from "./inquiry-form";
import { DialogService } from "fw-dialog";
import { SubmitInquiryAction } from "state/actions";
import { FormWithAnswers, validateAll, FormResolverService, } from "shared/form-runtime";
import { inquiryResponseFormCreator, } from "forms/inquiry-response";
import { Notification } from "service/notification";
import { CeebSearch } from "./steps/ceeb-search";
let FormView = class FormView {
    constructor(formResolverService, dialogService, notifier, contextStore, nav) {
        this.formResolverService = formResolverService;
        this.dialogService = dialogService;
        this.notifier = notifier;
        this.contextStore = contextStore;
        this.nav = nav;
        this.key = "";
        this.resolved = null;
        this.responseForm = inquiryResponseFormCreator(null);
        this.saving = false;
        this.answers = [];
        this.seenQuestionKeys = [];
        this.showValidation = false;
    }
    activate(params) {
        this.key = params.key;
    }
    get form() {
        const f = this.contextStore.state.portal.Forms.find((a) => a.PageSlug == this.key);
        if (f == null)
            return null;
        return this.contextStore.state.forms.find((form) => form.Id == f.FormId);
    }
    get fileHash() {
        return {};
    }
    updateResolved(resolved) {
        this.resolved = resolved;
    }
    openCeebRequestPopup(question) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.dialogService.open(CeebSearch, {
                question,
            });
            return res.canceled ? null : res.result;
        });
    }
    jumpToQuestion() {
        setTimeout(() => {
            const element = document.getElementsByClassName("validation");
            if (element[0] && element[0].closest(".question")) {
                element[0].closest(".question").scrollIntoView({ behavior: "smooth" });
                return;
            }
            if (element[0] && element[0].closest(".form-section")) {
                element[0]
                    .closest(".form-section")
                    .scrollIntoView({ behavior: "smooth" });
                return;
            }
        }, 200);
    }
    submit() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.showValidation = true;
            this.saving = true;
            try {
                this.responseForm.Answers = this.formResolverService.pruneAnswers(this.form, this.answers);
                const isValid = validateAll(this.form, this.answers);
                this.responseForm.validate();
                if (!isValid)
                    throw new Error("Validation failed");
                yield dispatch(new SubmitInquiryAction(this.key, this.responseForm, this.seenQuestionKeys, false));
                this.nav.navigate("/complete");
            }
            catch (err) {
                let message = "An error occurred while submitting the form.";
                if (err instanceof NetworkException && ((_a = err.result) === null || _a === void 0 ? void 0 : _a.Message)) {
                    message = (_b = err.result) === null || _b === void 0 ? void 0 : _b.Message;
                }
                this.notifier.error(message);
                this.jumpToQuestion();
            }
            this.saving = false;
        });
    }
    get shouldUseFullName() {
        if (this.form == null) {
            return false;
        }
        return !!this.contextStore.state.formsUseFullName[this.form.Id];
    }
};
FormView = __decorate([
    inject,
    needs(InquiryForm, FormWithAnswers),
    __metadata("design:paramtypes", [FormResolverService,
        DialogService,
        Notification,
        ContextStore,
        Navigator])
], FormView);
export { FormView };

FormView.__template = require('./form-view.html');
