var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, makeVueComponent } from "@derekpitt/fw";
import { cloneOf } from "fw-model";
import { DialogController } from "fw-dialog";
import { FormAnswer } from "../models";
import { validateAll } from "../validation";
let AddTableSectionRow = class AddTableSectionRow {
    constructor(controller) {
        this.controller = controller;
        this.component = null; // to get around a circular reference :(
        this.form = null;
        this.section = null;
        this.answers = [];
        this.baseAnswers = [];
        this.seenQuestionKeys = [];
        this.isNew = true;
        this.isInvalid = false;
    }
    activate(params) {
        this.component = makeVueComponent(params.component);
        this.section = params.section;
        this.answers = (params.answers || []).filter(a => a !== undefined).map(a => cloneOf(FormAnswer, a));
        this.baseAnswers = params.baseAnswers || [];
        this.isNew = !!params.isNew;
        this.readonly = params.questionOptions.readonly;
        this.fileHash = params.questionOptions.fileHash;
        this.fileService = params.questionOptions.fileService;
        this.requestCeeb = params.questionOptions.requestCeeb;
        this.decryptData = params.questionOptions.decryptData;
        this.displayonly = params.questionOptions.displayonly;
        this.showValidation = params.questionOptions.showValidation;
        this.showOptions = params.questionOptions.showOptions;
        this.form = params.form;
    }
    ok() {
        if (!validateAll(this.form, this.answers)) {
            this.showValidation = true;
            this.isInvalid = true;
            return;
        }
        this.isInvalid = false;
        this.controller.ok(this.answers);
    }
    cancel() {
        this.controller.cancel();
    }
};
AddTableSectionRow = __decorate([
    inject,
    __metadata("design:paramtypes", [DialogController])
], AddTableSectionRow);
export { AddTableSectionRow };

AddTableSectionRow.__template = require('./add-table-section-row.html');
