var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { prop, inject, ComponentEventBus } from "fw";
let uniqueId = 0;
let DropdownField = class DropdownField {
    constructor(componentEventBus) {
        this.componentEventBus = componentEventBus;
        this.uniqueId = uniqueId++;
        this.isFocused = false;
        this.elementId = "";
    }
    labelChanged() {
        this.elementId = `${this.label ? this.label.replace(/\s/g, '') : ""}-sf-${this.uniqueId}`;
    }
    onFocus() { this.isFocused = true; }
    onBlur() { this.isFocused = false; }
    get localValue() {
        return this.value;
    }
    set localValue(val) {
        this.componentEventBus.dispatch("update:value", val);
    }
};
__decorate([
    prop(null),
    __metadata("design:type", String)
], DropdownField.prototype, "label", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownField.prototype, "value", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownField.prototype, "validation", void 0);
__decorate([
    prop(() => []),
    __metadata("design:type", Array)
], DropdownField.prototype, "options", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], DropdownField.prototype, "disabled", void 0);
DropdownField = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], DropdownField);
export { DropdownField };

DropdownField.__template = require('./dropdown-field.html');
