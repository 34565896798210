var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { mergeFilterData } from "../../helpers";
import { countries, regions } from "../../../../countries/index";
import { Question, FormAnswer } from "../../models";
let AddressType = class AddressType {
    constructor(ecb) {
        this.ecb = ecb;
        this.address1 = "";
        this.address2 = "";
        this.city = "";
        this.region = "";
        this.country = "";
        this.tempCountry = "";
        this.postalCode = "";
        this.countryText = null;
        this.regionText = null;
    }
    attached() {
        this.answerChanged();
    }
    get countries() {
        return mergeFilterData(countries, this.country);
    }
    get regions() {
        return mergeFilterData(regions[this.country], this.region);
    }
    updateText() {
        const regionOption = this.regions ? this.regions.find(o => o.value == this.region) : null;
        this.regionText = regionOption != null ? regionOption.text : this.region;
        const countryOption = this.countries.find(o => o.value == this.country);
        this.countryText = countryOption != null ? countryOption.text : this.country;
    }
    answerChanged() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (typeof ((_a = this.answer) === null || _a === void 0 ? void 0 : _a.Data) === "undefined")
            return;
        this.tempCountry = (_b = this.answer.Data) === null || _b === void 0 ? void 0 : _b["country"];
        this.address1 = (_c = this.answer.Data) === null || _c === void 0 ? void 0 : _c["address1"];
        this.address2 = (_d = this.answer.Data) === null || _d === void 0 ? void 0 : _d["address2"];
        this.city = (_e = this.answer.Data) === null || _e === void 0 ? void 0 : _e["city"];
        this.region = (_f = this.answer.Data) === null || _f === void 0 ? void 0 : _f["region"];
        this.country = (_g = this.answer.Data) === null || _g === void 0 ? void 0 : _g["country"];
        this.postalCode = (_h = this.answer.Data) === null || _h === void 0 ? void 0 : _h["postalCode"];
        this.updateText();
    }
    change() {
        let { address1, address2, city, country, postalCode, region } = this;
        if (country && this.tempCountry !== country) {
            region = "";
            this.region = "";
            this.tempCountry = country;
        }
        if (address1.trim().length > 0 ||
            address2.trim().length > 0 ||
            city.trim().length > 0 ||
            country.trim().length > 0 ||
            postalCode.trim().length > 0 ||
            region.trim().length > 0) {
            this.answer.Data = {
                address1,
                address2,
                city,
                country,
                postalCode,
                region,
            };
        }
        else {
            this.answer.Data = null;
        }
        this.updateText();
        this.ecb.dispatch("answer-changed");
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], AddressType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], AddressType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], AddressType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], AddressType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], AddressType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], AddressType.prototype, "ariaLabelledBy", void 0);
AddressType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], AddressType);
export { AddressType };

AddressType.__template = require('./address.html');
