var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { needs, inject, prop, ComponentEventBus } from "fw";
import { Question, FormResolverService, } from "shared/form-runtime";
import { Form } from "models/form";
import { FileService } from "service/file";
import { UtilRepository } from "network/util-repository";
let InquiryForm = class InquiryForm {
    constructor(formService, utilRepo, ceb, fileService) {
        this.formService = formService;
        this.utilRepo = utilRepo;
        this.ceb = ceb;
        this.fileService = fileService;
        this.resolved = null;
    }
    attached() {
        this.answerChanged();
    }
    answerChanged() {
        return __awaiter(this, void 0, void 0, function* () {
            // re eval the question list
            const result = yield this.formService.resolveForm(this.form, this.answerHash);
            this.resolved = result.sections;
            this.ceb.dispatch("resolved-changed", this.resolved);
        });
    }
    decryptData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.utilRepo.decrypt(data);
        });
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Form)
], InquiryForm.prototype, "form", void 0);
__decorate([
    prop(() => ({})),
    __metadata("design:type", Object)
], InquiryForm.prototype, "answerHash", void 0);
__decorate([
    prop(() => ({})),
    __metadata("design:type", Object)
], InquiryForm.prototype, "fileHash", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Object)
], InquiryForm.prototype, "readonly", void 0);
InquiryForm = __decorate([
    inject,
    needs(Question),
    __metadata("design:paramtypes", [FormResolverService,
        UtilRepository,
        ComponentEventBus,
        FileService])
], InquiryForm);
export { InquiryForm };

InquiryForm.__template = require('./inquiry-form.html');
