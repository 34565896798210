var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { NumberQuestionType } from "../../enums";
import { Question, FormAnswer } from "../../models";
let NumberType = class NumberType {
    constructor(ecb) {
        this.ecb = ecb;
    }
    change() {
        this.answer.Number = this.answer.Text != null ? parseFloat(this.answer.Text) : null;
        this.ecb.dispatch("answer-changed");
    }
    get valueText() {
        if (this.answer != null && this.answer.Number != null) {
            return this.answer.Number.toFixed(this.question.Options.Number.Type == NumberQuestionType.Integer ? 0 : 2);
        }
        else {
            return "";
        }
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], NumberType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], NumberType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NumberType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NumberType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], NumberType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], NumberType.prototype, "ariaLabelledby", void 0);
NumberType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], NumberType);
export { NumberType };

NumberType.__template = require('./number.html');
