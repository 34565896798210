var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
let EncryptedType = class EncryptedType {
    constructor(ceb) {
        this.ceb = ceb;
        this.maxLength = 80;
        this.revealing = false;
        this.remasked = false;
        this.isChanged = false;
    }
    attached() {
        this.maxLength = this.question.Options.MaxLength;
    }
    change() {
        this.answer.Data = null;
        this.ceb.dispatch("answer-changed");
    }
    changeInput() {
        this.isChanged = true;
    }
    get hasText() {
        var _a, _b;
        return !!((_b = (_a = this.answer) === null || _a === void 0 ? void 0 : _a.Text) === null || _b === void 0 ? void 0 : _b.trim().length);
    }
    get isEncrypted() {
        var _a;
        return (_a = this.answer) === null || _a === void 0 ? void 0 : _a.IsEncrypted;
    }
    get showDecrypt() {
        return (this.hasText &&
            this.decryptData != null &&
            this.isEncrypted);
    }
    get showMasked() {
        return this.isEncrypted || this.remasked;
    }
    remask() {
        this.isChanged = false;
        this.remasked = !this.remasked;
    }
    reveal() {
        return __awaiter(this, void 0, void 0, function* () {
            this.revealing = true;
            this.remasked = false;
            this.isChanged = false;
            try {
                const data = yield this.decryptData(this.answer.Text);
                this.answer.Text = data;
                this.answer.IsEncrypted = false;
            }
            catch (err) {
            }
            this.revealing = false;
        });
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], EncryptedType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], EncryptedType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], EncryptedType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], EncryptedType.prototype, "displayonly", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], EncryptedType.prototype, "decryptData", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], EncryptedType.prototype, "ariaLabelledby", void 0);
EncryptedType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], EncryptedType);
export { EncryptedType };

EncryptedType.__template = require('./encrypted.html');
