var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
let LongTextType = class LongTextType {
    constructor(ecb) {
        this.ecb = ecb;
    }
    change() {
        this.ecb.dispatch("answer-changed");
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], LongTextType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], LongTextType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], LongTextType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], LongTextType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], LongTextType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "ariaLabelledby", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "showOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], LongTextType.prototype, "decryptData", void 0);
LongTextType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], LongTextType);
export { LongTextType };

LongTextType.__template = require('./long-text.html');
