var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
let CeebType = class CeebType {
    constructor(ecb) {
        this.ecb = ecb;
        this.outerDiv = null;
    }
    callRequestCeeb(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.requestCeeb == null)
                return;
            try {
                const res = yield this.requestCeeb(this.question, e.srcElement || e.target);
                this.outerDiv.focus();
                if (res == null)
                    return;
                this.answer.Data = res;
                this.change();
            }
            catch (err) { }
        });
    }
    clear() {
        this.answer.Data = null;
        this.change();
    }
    change() {
        this.ecb.dispatch("answer-changed");
    }
    get ceebAddress() {
        if (this.answer == null)
            return "";
        const addressComponents = [this.answer.Data["city"]];
        const region = this.answer.Data["region"];
        const country = this.answer.Data["country"];
        if (region != null && region.trim().length > 0)
            addressComponents.push(region);
        if (country != null && country.trim().length > 0)
            addressComponents.push(country);
        return addressComponents.join(", ");
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], CeebType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], CeebType.prototype, "answer", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Function)
], CeebType.prototype, "requestCeeb", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CeebType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CeebType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], CeebType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], CeebType.prototype, "ariaLabelledBy", void 0);
CeebType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], CeebType);
export { CeebType };

CeebType.__template = require('./ceeb.html');
