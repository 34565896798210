var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject, prop, ComponentEventBus } from "@derekpitt/fw";
import { Question, FormAnswer } from "../../models";
let DropdownType = class DropdownType {
    constructor(ecb) {
        this.ecb = ecb;
        this.value = null;
        this.valueText = null;
    }
    attached() {
        this.answerChanged();
    }
    answerChanged() {
        const { FormAnswerOption } = this.answer;
        this.value = FormAnswerOption != null ? FormAnswerOption.AnswerOptionId : null;
        const option = this.question.AnswerOptions.find(o => o.Id == this.value);
        this.valueText = option != null ? option.Label : null;
    }
    change() {
        const option = this.question.AnswerOptions.find(o => o.Id == this.value);
        this.valueText = option != null ? option.Label : null;
        this.answer.FormAnswerOption = this.value != null ? { AnswerOptionId: this.value, OptionText: this.valueText } : null;
        this.ecb.dispatch("answer-changed");
    }
};
__decorate([
    prop(null),
    __metadata("design:type", Question)
], DropdownType.prototype, "question", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Array)
], DropdownType.prototype, "answerOptions", void 0);
__decorate([
    prop(null),
    __metadata("design:type", FormAnswer)
], DropdownType.prototype, "answer", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], DropdownType.prototype, "readonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], DropdownType.prototype, "displayonly", void 0);
__decorate([
    prop(false),
    __metadata("design:type", Boolean)
], DropdownType.prototype, "compact", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownType.prototype, "ariaLabelledby", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownType.prototype, "requestCeeb", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownType.prototype, "fileHash", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownType.prototype, "fileService", void 0);
__decorate([
    prop(null),
    __metadata("design:type", Object)
], DropdownType.prototype, "showOptions", void 0);
DropdownType = __decorate([
    inject,
    __metadata("design:paramtypes", [ComponentEventBus])
], DropdownType);
export { DropdownType };

DropdownType.__template = require('./dropdown.html');
